import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./Subtitles.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { ReactMic } from "../react-mic/src";
import apiSocket from "../../services/socket";
import words from "../../assets/words-circal";
import { FaCirclePlus } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";

import Papa from "papaparse";
import LanguageSelect from "../inputSelect/LanguageSelect";
import socket from "../../services/socket";
const API_KEY = "IUvBk9zz5hOAAhhfRtkhbtFe7PSAvaAi";

export default function Subtitles({
  device,
  language,
  muted,
  isGuest,
  code,
  setSubtitlesSecondaryLanguage,
  stContainerSize,
  isSRT,
}) {
  const [recognize, setRecognize] = useState(false);
  const [recording, setRecording] = useState(false);
  const { user } = useAuth();
  const userId = useMemo(() => user?.id, [user]);
  const endAnchor = useRef(null);
  const secondaryEndAnchor = useRef(null);
  const [result, setResult] = useState({ transcript: "", language: "" });
  const [newLine, setNewLine] = useState(null);
  const [lines, setLines] = useState([]);
  const [customDictionary, setCustomDictionary] = useState([]);
  const [secondaryLanguage, setSecondaryLanguage] = useState();
  const [secondaryLines, setSecondaryLines] = useState([]);
  const [isUsingSecondaryLanguage, setIsUsingSecondaryLanguage] =
    useState(false);

  const [isGeneratingSRT, setIsGeneratingSRT] = useState(false); // Track if we are generating SRT
  const [currentTime, setCurrentTime] = useState(0); // Track current timestamp
  const [sentenceBuffer, setSentenceBuffer] = useState(""); // Buffer for the sentence
  const [sentenceStartTime, setSentenceStartTime] = useState(null); // Track sentence start time

  const smSocket = useRef({});
  const buffer = useRef(new Blob());
  const srtLines = useRef([]); // Store all lines for SRT

  const processCSVData = (csvData) => {
    Papa.parse(csvData, {
      header: false,
      skipEmptyLines: true,
      complete: (results) => {
        const speechmaticsDict = {};
        const headers = results.data[0]; // First row is headers
        const langPronunciationPairs = {};

        headers.forEach((header) => {
          const match = header.match(/language_(.*)/);
          if (match) {
            const langCode = match[1];
            langPronunciationPairs[langCode] = {
              language: header,
              pronunciation: `pronunciation_${langCode}`,
            };
          }
        });

        results.data.slice(1).forEach((row) => {
          Object.keys(langPronunciationPairs).forEach((langCode) => {
            const pair = langPronunciationPairs[langCode];

            // Check if the current language is present in the row
            if (row[headers.indexOf(pair.language)]) {
              if (!speechmaticsDict[langCode]) {
                speechmaticsDict[langCode] = [];
              }

              // Process pronunciations
              const pronunciations = row[headers.indexOf(pair.pronunciation)]
                ? row[headers.indexOf(pair.pronunciation)]
                    .split(",")
                    .map((p) => p.trim())
                : [];
              const res = {
                content: row[headers.indexOf(pair.language)].trim(),
              };
              if (pronunciations.length > 0) {
                res.sounds_like = pronunciations;
              }
              speechmaticsDict[langCode].push(res);
            }
          });
        });
        setCustomDictionary(speechmaticsDict[language] || []);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const pushLine = useCallback(
    (text, userId, userName) => {
      if (!text) return;

      const newLines = [...lines];
      const punctuationMarks = [
        ".",
        ",",
        "!",
        "?",
        ";",
        ":",
        "-",
        ")",
        "(",
        "[",
        "]",
        "{",
        "}",
      ];

      // Merge lines if from the same user and close in content
      if (
        lines.length > 0 &&
        newLines[lines.length - 1].userId === userId &&
        newLines[lines.length - 1].userName === userName
      ) {
        const lastLine = newLines[lines.length - 1].text;
        const endsWithPunctuation = punctuationMarks.includes(
          lastLine[lastLine.length - 1]
        );

        if (punctuationMarks.includes(text[0]) && !endsWithPunctuation) {
          newLines[lines.length - 1].text = lastLine.trimEnd() + text;
        } else {
          newLines[lines.length - 1].text += " " + text;
        }
      } else {
        newLines.push({
          userName,
          text: `${userName} : ${text}`,
          userId,
        });
      }

      setLines(newLines.slice(-20));

      // Accumulate text in the sentence buffer
      let localBuffer = sentenceBuffer + " " + text;

      setSentenceBuffer((prevBuffer) => prevBuffer + " " + text);
      // Check if the sentence ends with punctuation (complete sentence)
      const endsWithPunctuation = punctuationMarks.some((mark) =>
        localBuffer.trim().endsWith(mark)
      );

      if (endsWithPunctuation) {
        const endTime = currentTime; // Current time when the sentence is validated
        const startTime = sentenceStartTime || currentTime; // The time when buffering started

        const formatTimeForSRT = (timeInSeconds) => {
          const date = new Date(timeInSeconds * 1000); // Convert seconds to milliseconds
          const hours = String(date.getUTCHours()).padStart(2, "0"); // Get hours and pad to 2 digits
          const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // Get minutes and pad to 2 digits
          const seconds = String(date.getUTCSeconds()).padStart(2, "0"); // Get seconds and pad to 2 digits
          const milliseconds = String(date.getUTCMilliseconds()).padStart(
            3,
            "0"
          ); // Get milliseconds and pad to 3 digits

          return `${hours}:${minutes}:${seconds},${milliseconds}`; // Format as HH:MM:SS,MS
        };
        // Format time for SRT
        const startTimeFormatted = formatTimeForSRT(startTime);
        const endTimeFormatted = formatTimeForSRT(endTime);

        const limitLineLength = (text) => {
          const words = text.trim().split(/\s+/); // Split by whitespace
          const result = [];
          let count = 0;

          words.forEach((word) => {
            result.push(word);
            count++;

            // After every 15 words, add '{/t}'
            if (count === 15) {
              result.push("\n");
              count = 0; // Reset count after adding '{/t}'
            }
          });

          return result.join(" "); // Join words back into a string
        };

        // Add the buffered sentence to SRT
        console.log("SRTLINES", srtLines);
        const srtLine = {
          index: srtLines.current.length > 0 ? srtLines.current.length + 1 : 1,
          startTime: startTimeFormatted,
          endTime: endTimeFormatted,
          text: limitLineLength(localBuffer.trim()),
        };

        saveToSRTFile(srtLine); // Save the sentence to the SRT file

        // Reset the buffer after adding the sentence
        setSentenceBuffer("");
        setSentenceStartTime(null); // Reset start time for the next sentence
        localBuffer = ""; // Reset local buffer
      } else {
        // If the sentence hasn't finished, we update the start time to the current time
        if (sentenceStartTime === null) {
          setSentenceStartTime(currentTime);
        }
      }
    },
    [lines, isGeneratingSRT, currentTime, sentenceBuffer, sentenceStartTime]
  );

  useEffect(() => {
    if (!newLine || !newLine.text) return;
    console.log("newLine", newLine);
    pushLine(newLine.text, newLine.userId, newLine.userName);
  }, [newLine]);

  useEffect(() => {
    endAnchor.current?.scrollIntoView({ behavior: "smooth" });
    secondaryEndAnchor.current?.scrollIntoView({ behavior: "smooth" });
  }, [lines, secondaryLines]);

  const initSmSocket = async () => {
    try {
      const res = await fetch(
        "https://mp.speechmatics.com/v1/api_keys?type=rt",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
          body: JSON.stringify({
            ttl: 3600,
          }),
        }
      );
      const { key_value } = await res.json();

      console.log("key_value", key_value);
      smSocket.current = new WebSocket(
        `wss://eu2.rt.speechmatics.com/v2/${language}?jwt=${key_value}`
      );
      smSocket.current.onerror = (event) => {
        console.log("socket error", event);
      };
      smSocket.current.onclose = (event) => {
        console.log("socket closed", event);
        // setTimeout(() => window.location.reload(), 2000);
      };
      smSocket.current.onopen = (event) => {
        console.log("socket open", event);
        console.log("custom_dictionary", customDictionary);
        smSocket.current.send(
          JSON.stringify({
            message: "StartRecognition",
            audio_format: {
              type: "raw",
              encoding: "pcm_s16le",
              sample_rate: 48000,
            },
            transcription_config: {
              language: language,
              max_delay: 5,
              max_delay_mode: "flexible",
              additional_vocab: customDictionary,
              // profanity: true,
            },
          })
        );
      };

      smSocket.current.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        if (data.message === "AddTranscript") {
          const { transcript } = data.metadata;
          if (transcript) {
            setResult({ transcript, language });
          }
        }
      });
    } catch (err) {
      console.log("Error initSmSocket", err);
    }
  };

  const isSocketOpen = (socket) => {
    return socket !== null && socket !== undefined && socket.readyState === 1;
  };

  useEffect(() => {
    if (language && !isGuest) {
      setRecording(true);
      apiSocket.emit("request-csv", code);
      apiSocket.on("csv-data", (csvData) => {
        if (csvData) {
          processCSVData(csvData);
        } else {
          initSmSocket();
        }
      });
    } else {
      setRecording(false);
    }
  }, [language]);

  // fetch(testCsv)
  //       .then((response) => response.text())
  //       .then((csvData) => {
  //         processCSVData(csvData, language);
  //       })
  //       .catch((error) => console.error("Error fetching CSV:", error));

  useEffect(() => {
    if (language && !isGuest) {
      initSmSocket();
    }
  }, [customDictionary]);

  useEffect(() => {
    apiSocket.on("messages:add", (data) => {
      console.log("New Message", data);
      if (
        data.language &&
        data.language === language &&
        !data.secondaryLanguage
      ) {
        if (data.text)
          setNewLine({
            text: data.text,
            userId: data.userId !== null ? data.userId : data.userName,
            userName: data.userName,
          });
      }
      if (data.language === secondaryLanguage) {
        const { text, userId, userName } = data;
        const localUserId = userId !== null ? userId : userName;
        console.log("DATA", data);
        const newSecondaryLines = [...secondaryLines];
        if (
          secondaryLines.length > 0 &&
          newSecondaryLines[secondaryLines.length - 1].userId === localUserId
        ) {
          newSecondaryLines[secondaryLines.length - 1].text +=
            ([".", ","].includes(text[0]) ? "" : " ") + text;
        } else {
          newSecondaryLines.push({
            text: `${userName} : ${text}`,
            userId: localUserId,
          });
        }
        setSecondaryLines(newSecondaryLines.slice(-20));
      }
    });
    return () => {
      console.log("REMOVED");
      apiSocket.removeListener("messages:add");
      return null;
    };
  }, [secondaryLines, language, secondaryLanguage]);

  // useEffect(() => {
  //   const handleSecondaryLanguage = (data) => {
  //     console.log("DATA secondarylang", data);
  //     console.log("secondaryLanguage", secondaryLanguage);
  //     if (data.language === secondaryLanguage) {
  //       const { text, userId, userName } = data;
  //       const newSecondaryLines = [...secondaryLines];
  //       if (
  //         secondaryLines.length > 0 &&
  //         newSecondaryLines[secondaryLines.length - 1].userId === userId
  //       ) {
  //         newSecondaryLines[secondaryLines.length - 1].text +=
  //           ([".", ","].includes(text[0]) ? "" : " ") + text;
  //       } else {
  //         newSecondaryLines.push({
  //           text: `${userName} : ${text}`,
  //           userId,
  //         });
  //       }
  //       setSecondaryLines(newSecondaryLines.slice(-20));
  //     }
  //   };
  //   apiSocket.on("messages:add", handleSecondaryLanguage);
  //   return () => {
  //     apiSocket.off("messages:add", handleSecondaryLanguage);
  //   };
  // }, [secondaryLanguage, secondaryLines]);

  const translateResult = async (result) => {
    const { transcript: text } = result;
    if (!text) return;

    apiSocket.emit("messages:add", { text, language });

    if (language === result.language) {
      setNewLine({
        text,
        userId: user?.id || null,
        userName: user
          ? `${user?.firstname} ${user?.lastname}`
          : `Guest_${socket.id.slice(0, 5)}`,
      });
    }
  };

  useEffect(() => {
    translateResult(result);
  }, [result]);

  useEffect(() => {
    if (recognize) {
      recognizeData();
      setRecognize(false);
    }
  }, [recognize]);

  const onData = (data) => {
    buffer.current = new Blob([buffer.current, data]);
    if (buffer.current.size >= 16384) {
      setRecognize(true);
    }
  };

  const recognizeData = async () => {
    if (isSocketOpen(smSocket.current)) {
      const data = buffer.current;
      const chunk = data.slice(0, 16384);
      buffer.current = data.slice(16384);
      const rawData = await chunk.arrayBuffer();
      smSocket.current.send(rawData);
    }
  };

  const handleCloseSecondaryLanguage = () => {
    setIsUsingSecondaryLanguage(false);
    setSecondaryLanguage(null);
    setSubtitlesSecondaryLanguage(null);
  };

  useEffect(() => {
    console.log("secondaryLanguage", secondaryLanguage);
    apiSocket.emit("sessions:update", { secondaryLanguage });
  }, [secondaryLanguage]);

  // SRT GENERATION

  useEffect(() => {
    let interval;
    if (isGeneratingSRT) {
      interval = setInterval(() => {
        setCurrentTime((prevTime) => prevTime + 1); // Increment time every second
      }, 1000); // Update every centi second
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval); // Clean up interval
  }, [isGeneratingSRT]); // Dependency on isGeneratingSRT

  const saveToSRTFile = (line) => {
    // Add the new line to the SRT buffer
    srtLines.current.push({
      index: line.index,
      startTime: line.startTime,
      endTime: line.endTime,
      text: line.text,
    });

    // Optionally, log the line
    console.log("SRT Line added:", line);
  };

  const downloadSRTFile = () => {
    // Generate the SRT content from the accumulated lines
    const srtContent = srtLines.current
      .map((line) => {
        return `${line.index}\n${line.startTime} --> ${line.endTime}\n${line.text}\n\n`;
      })
      .join(""); // Join all lines into a single string

    // Create a Blob with the SRT content and a downloadable link
    const blob = new Blob([srtContent], { type: "text/srt" });
    const url = URL.createObjectURL(blob);

    // Create a download link and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "subtitles.srt"; // Name of the file
    document.body.appendChild(a); // Append the link to the DOM
    a.click(); // Programmatically click the link to download the file
    document.body.removeChild(a); // Clean up after download

    // Optionally, you can revoke the object URL to free up resources
    URL.revokeObjectURL(url);
    setIsGeneratingSRT(false); // Reset the SRT generation state
  };

  return (
    <div className={styles.subtitlesContainer}>
      <div
        className={styles.container}
        style={
          stContainerSize && stContainerSize === "sm" ? { height: 130 } : {}
        }
      >
        {!isGuest && (
          <ReactMic
            record={recording && !muted}
            className={styles.reactMic}
            onData={onData}
            strokeColor="blue"
            backgroundColor="white"
            bufferSize={512}
            mimeType="audio/wav"
            deviceId={device || undefined}
            channelCount={2}
            echoCancellation={true}
            autoGainControl={true}
            noiseSuppression={true}
            sampleRate={48000}
          />
        )}
        {!!lines && lines.length > 0 && (
          <div className={styles.subtitles}>
            {lines.map((l, i) => (
              <p
                key={i}
                className={
                  styles.text +
                  " " +
                  (l.userId === userId ? styles.color0 : styles.color0)
                }
              >
                {l.text}
              </p>
            ))}
            <div ref={endAnchor} />
          </div>
        )}
      </div>
      {isSRT && (
        <>
          <button
            onClick={() => setIsGeneratingSRT(!isGeneratingSRT)}
            className={styles.srtButton}
          >
            {isGeneratingSRT ? "Stop SRT Generation" : "Start SRT Generation"}
          </button>
          {isGeneratingSRT && (
            <button onClick={downloadSRTFile}>Download SRT</button>
          )}
        </>
      )}

      <div className={styles.secondaryLanguageContainer}>
        {!isUsingSecondaryLanguage ? (
          <button
            className={styles.secondaryLanguageButton}
            onClick={() => setIsUsingSecondaryLanguage(true)}
          >
            <FaCirclePlus color="white" size={25} />
          </button>
        ) : (
          <div
            className={styles.container}
            style={
              stContainerSize && stContainerSize === "sm" ? { height: 130 } : {}
            }
          >
            <div className={styles.secondaryLanguageHeader}>
              <button
                className={styles.secondaryLanguageButton}
                onClick={handleCloseSecondaryLanguage}
              >
                <IoMdClose color="white" size={25} />
              </button>
              <LanguageSelect
                onChange={(lang) => {
                  setSecondaryLanguage(lang);
                  setSubtitlesSecondaryLanguage(lang);
                }}
              />
            </div>
            {!!secondaryLines && secondaryLines.length > 0 && (
              <div className={styles.subtitles}>
                {secondaryLines.map((l, i) => (
                  <p
                    key={i}
                    className={
                      styles.text +
                      " " +
                      (l.userId === userId ? styles.color0 : styles.color0)
                    }
                  >
                    {l.text}
                  </p>
                ))}
                <div ref={secondaryEndAnchor} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
